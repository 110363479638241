import React from "react"

const LayoutInner = ({location, children}) => {


    return (
        <div
            style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: 1200,
                padding: 0,
                paddingLeft: 16,
                paddingRight: 16,

            }}
        >

            {children}

        </div>
    )
}

export default LayoutInner
