import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layoutV2"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInnerV2";
import {keys} from "lodash"
import * as PATHS from "../../utils/paths";
import "../../utils/index"
import ContactForm from '../../components/ContactForm'
import { isValidEmailAddress } from '../../utils'
import {Button, Grid, Hidden} from "@material-ui/core";
import {WORKSHOPS_EQUITABLE_EPIDEMIOLOGY} from "../../utils/paths";
import LayoutInnerV2 from "../../components/layoutInnerV2";

const styles = theme => ({
    root: {},
    title: {
        fontSize: 44,
        lineHeight: 1.2,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        },
    },
    heroText: {
        fontSize: 20,
        lineHeight: 1.4,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    smallText: {
        fontSize: 18,
        lineHeight: 1.4,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
    headImgWrap: {
        // maxWidth: "100%",
        position: "relative",
        // backgroundImage: "url(https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-head.png)",
        // height: "100%",
        // backgroundSize: "120%",
        display: "flex",
        flexDirection: "column",
        // flex: 1,
        // backgroundColor: "blue",
        minHeight: "100%",

    },
    headImgWrapInner: {
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        flexGrow: 1,

    },
    headImg: {
        position: "absolute",
        // left: "-4%",
        // right: "-13.8%",
        right: "-18%",
        top: "-7.2%",
        maxHeight: "107%",

        maxWidth: "123%",

    },
    spacer: {
        height: 96,
    },
    quoteText: {
        backgroundColor: "#F7F7F7",
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 4,
        fontStyle: "italic",
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },

    },
    quotePerson: {
        fontSize: 16,
        lineHeight: 1.2,
        color: "#0E0E0E",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    workshopList: {
        paddingLeft: "1.2em",
        lineHeight: 1,
        "& li": {
            marginBottom: 12,
            fontSize: 16,
            lineHeight: 1.4,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        }
    },
    workshopPricingList: {
        paddingLeft: ".8em",
        lineHeight: 1,
        "& li": {
            // marginBottom: 12,
            fontSize: 16,
            lineHeight: 1.4,
            marginBottom: 12,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        }
    }
});



const SectionContainer = ({title, children}) => {
    return (
        <Grid container spacing={0} direction={'row'} style={{marginBottom: 96}}>
            <Grid item sm={4}><h3>{title}</h3></Grid>
            <Grid item sm={8}>
                {children}
            </Grid>

        </Grid>)
}


const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={true}>
            <SEO title={"Workshop: Equitable Epidemiology"} location={{pathname: PATHS.WORKSHOPS_EQUITABLE_EPIDEMIOLOGY}}/>


            <LayoutInner>
                <Grid container
                      spacing={0} direction={'row'}
                      sx={{
                          // justifyContent: "center",
                          alignItems: "stretch",
                      }}
                    // alignItems={"stretch"}
                >


                    <Grid item xs={12} sm={5}>
                        <h1 className={classes.title}>Equitable Epidemiology: Deep Dive Workshops for Public Health
                            Communicators</h1>
                        <p className={classes.heroText}>
                            Can risk ratios be risky? Can proportional mortality rates disproportionately increase
                            mortality? Can adhering to communication conventions undermine adherence? Epidemiology
                            conventions for communicating population health outcomes, which may be perfectly suitable
                            published in The Lancet, require a different set of considerations when produced for the
                            public.
                        </p>
                        <p className={classes.heroText}>
                            In these deep-dive workshops, we’ll seek to motivate an elevated duty-of-care for public
                            health data communication, cover techniques for identifying prevalent risks in messaging,
                            and propose alternative, evidence-backed chart choices for common use cases for visualizing
                            population health outcomes.
                        </p>
                        <p className={classes.heroText}>Please get in touch to book a time or a schedule a custom
                            training.</p>
                        <Link to={"#get-in-touch"} style={{textDecoration: "none"}}>
                            <Button variant={"contained"} color={"primary"} style={{width: "79%"}}>Get in touch</Button>
                        </Link>
                        <div className={classes.spacer}/>
                    </Grid>
                    <Grid item sm={1}/>
                    <Grid item xs={12} sm={6} style={{
                        // backgroundColor: "red",
                        maxHeight: "920px",
                        // height: "100%"
                    }}>
                        <div className={classes.headImgWrap}>
                            {/*<div className={classes.headImgWrapInner}>*/}

                            <img className={classes.headImg}
                                 src={"https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-head.png"}/>
                            {/*</div>*/}
                        </div>


                    </Grid>


                </Grid>
                <div className={classes.spacer}/>

                <SectionContainer title={"Workshop Overview"}>
                    <p className={classes.heroText}>
                        Equitable visualizations support healthier populations.
                        Thoughtful data design can play an
                        active role in correcting health misbeliefs and harmful stereotypes, as well as supporting
                        impactful communication goals like risk perception, public support, and behavioral
                        adherence. On the other hand, many popular, conventional ways of showing group health
                        outcomes can be actively harmful.</p>
                    <p className={classes.heroText}>
                        In this workshop, we’ll learn how to visualize population health
                        to support health outcomes
                        that are more equitable for communities of focus, and more effective for the whole
                        population in general.</p>
                    <br/>

                    <Grid container spacing={4} direction={'row'}>
                        <Grid item sm={6}>
                            <p className={classes.quoteText}>"Throughout the rest of our conference, we constantly
                                heard people talking about how enlightening and thought-provoking they found your
                                talk and how they will change their trainings and practices because of it."</p>
                            <p className={classes.quotePerson}>Regional HIV Advocacy & Education Organizer</p>
                        </Grid>
                        <Grid item sm={6}>
                            <p className={classes.quoteText}>"Hearing about this continues to make me consciously
                                examine my choices and think about what I'm doing and showing."</p>
                            <p className={classes.quotePerson}>Strategy & Performance Manager, Transforming
                                Aboriginal Outcomes, NSW Department of Communities and Justice</p>
                            <br/>
                            <p className={classes.quoteText}>"Thank you. This was fantastic (and unsettling)."</p>
                            <p className={classes.quotePerson}>SFDPH Analyst</p>
                        </Grid>

                    </Grid>
                </SectionContainer>


                <img style={{maxWidth: "100%"}}
                     src={"https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-sample-slides-preview.png"}/>
                <figcaption>A random sample of slides.</figcaption>

                <div className={classes.spacer}/>

                <SectionContainer title={"Learning Objectives"}>
                    <p className={classes.heroText}>After these workshops, health communicators will:</p>

                    <Grid container spacing={4} direction={'row'}>
                        <Grid item sm={6}>
                            <ol className={classes.workshopList}>
                                <li>Understand how data visualization can impact health communication goals like
                                    perceived vulnerability, public support for policies and interventions,
                                    behavioral intent, and attributions to social determinants of health.
                                </li>
                                <li>Appreciate how popular conventions for visualizing group outcomes can bias
                                    viewers’ interpretations of the data and their beliefs about health in
                                    general,
                                    and how these misbeliefs can actually undermine health outcomes for the
                                    populations being visualized.
                                </li>
                                <li>Be able to make alternative chart choices to more effectively, equitably convey
                                    population health outcomes.
                                </li>
                            </ol>
                        </Grid>
                    </Grid>
                </SectionContainer>


                <SectionContainer title={"Intended Audience"}>
                    <p className={classes.heroText}>The workshops are for health communicators involved in producing public-facing
                        visualizations of population health outcomes, in particular:</p>

                    <Grid container spacing={4} direction={'row'}>
                        <Grid item sm={6}>
                            <ul className={classes.workshopList}>
                                <li>Public Health Analysts</li>
                                <li>Educators, Communicators, and Administrators working at municipal, state, or
                                    federal health agencies
                                </li>
                                <li>Advocacy groups using data to promote health equity</li>
                                <li>Data journalists covering health policy</li>

                            </ul>
                        </Grid>
                    </Grid>
                </SectionContainer>


                <SectionContainer title={"Format and Structure"}>
                    <p className={classes.heroText}>
                        Material can be adapted based on your groups’ needs and interests, to cover various themes.
                        Each session requires 60-90 minutes, including a presentation of the topics, supporting
                        example redesigns, questions and discussion, and group exercises.

                        Workshop case studies can also be customized to handle your organizations’ specific needs
                        and data.
                    </p>
                </SectionContainer>


                <SectionContainer title={"Pricing"}>
                    <p className={classes.heroText}>
                        Pricing is flexible, but recommended rates are as follows:
                    </p>
                    <Grid container spacing={4} direction={'row'}>
                        <Grid item md={6}>
                            <p className={classes.smallText}><b>Large Group Talk:</b> To motivate the topic and
                                introduce the wider organization to
                                these concepts:</p>
                            <ul className={classes.workshopPricingList}>
                                <li>Single 45 minute primer talk, plus two case studies and Q&A</li>
                                <li>$2,000 per 100 attendees</li>
                            </ul>
                        </Grid>
                        <Grid item md={6}>

                            <b>Interactive Group Workshops:</b> To support group discussion and greater depth:
                            <ul className={classes.workshopPricingList}>
                                <li>Two to four deep dive sessions, including interactive group exercises and
                                    discussions
                                </li>
                                <li>$55 per participant per session, for 10–30 participants</li>
                            </ul>
                        </Grid>

                    </Grid>
                </SectionContainer>

                <SectionContainer title={""}>
                    <hr/>
                </SectionContainer>

                <a id={"get-in-touch"}/>
                <SectionContainer title={"Next Steps"}>

                    <p className={classes.heroText}>Please get in touch to book a time or a schedule a custom
                        training.</p>

                    <ul className={classes.heroText}>
                        <li>Email <a href={"mailto:hi+workshops@3iap.com"}>hi+workshops@3iap.com</a><br/></li>
                        <li>Book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a></li>
                        <li>Or use the form below to send a message</li>
                    </ul>

                    <ContactForm layoutComponent={({children}) => (<div>{children}</div>)}/>
                </SectionContainer>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
